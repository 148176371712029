<template>
  <b-overlay :show="isLoading || isSaving" rounded="sm">
    <template v-if="isEditingOwnCompany || isPartOfGroup">
      <div class="card card-custom gutter-b">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title" :class="{ 'align-items-start flex-column': isEditingOwnCompany }">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Company Group") }}
            </h3>
            <span v-if="isEditingOwnCompany" class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Update your company's groups") }}
            </span>
          </div>
          <div class="card-toolbar">
            <template v-if="isEditingOwnCompany">
              <button v-if="canCreateGroup" class="btn btn-light-primary font-weight-bolder" :disabled="!hasCompanies"
                @click.prevent="openCreateDialog">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Communication/Group.svg" />
                </span>
                {{ $t("Add group") }}
              </button>
              <div v-else>
                <button class="btn btn-light-primary font-weight-bolder"
                  @click.prevent="openEditDialog(companyGroups[0])">
                  <span class="svg-icon menu-icon">
                    <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                  </span>
                  {{ $t('Edit Group') }}
                </button>
                <button class="btn btn-light-primary font-weight-bolder ml-4" @click="openRemoveDialog(companyGroups[0])">
                  <span class="svg-icon menu-icon">
                    <inline-svg src="/media/svg/icons/General/Trash.svg" />
                  </span>
                  {{ $t('Delete Group') }}
                </button>
              </div>
            </template>
          </div>
        </div>
        <div v-if="companyGroups.length" class="card-body detail">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-muted font-weight-bolder text-uppercase">{{ $t('Company') }}</th>
                <th scope="col" class="text-muted font-weight-bolder text-uppercase">{{ $t('Own') }}</th>
                <th scope="col" class="text-muted font-weight-bolder text-uppercase">{{ $t('Address') }}</th>
                <th scope="col" class="text-muted font-weight-bolder text-uppercase">{{ $t('Nation') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="company in getCompany(companyGroups[0].companies)" :key="company.id">
                <td>
                  <div class="text-capitalize d-flex align-items-center">
                    <Avatar :avatar-image="company?.logo" :avatar-text="company?.name?.[0]" />
                    <div class="ml-3">
                      <div class="font-weight-bolder" :style="{ wordBreak: 'break-word' }">{{ company?.name }}</div>
                      <div v-if="company.description" class="text-muted">{{ company.description }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <span v-if="company.id == user.selected_company" class="font-weight-bolder text-muted">
                    {{ $t("Your company") }}
                  </span>
                  <span v-else class="font-weight-bolder text-muted">{{ $t("Company group") }}</span>
                </td>
                <td>
                  <span>{{ company?.companyaddress_set?.[0]?.address.formatted }}</span>
                </td>
                <td>
                  <country-flag :country-iso="company.country_of_establishment"></country-flag>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="card-body text-center">
          <p class="font-weight-bold font-size-h2">{{ $t('Company is not part of a group') }}</p>
          <p class="font-size-h4 pt-3 pb-8 ml-4 text-muted">
            <img src="/media/svg/icons/Code/Info-circle.svg" alt="info"/>  {{ $t('Here you can create a group of companies. Being part of a group enables the possibility to use cost reversal on assignments ') }}
          </p>
          <p v-show="!hasCompanies" class="text-danger font-weight-bold">
            {{ $t('Add at least one company or a physical person with a vat document to create a group of companies') }}
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title">
            <h3 class="card-label">
              {{ $t("Company Groups") }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex gap-3">
            <h4>{{ $t("Is this company part of your group?") }}</h4>
            <euro-radio :value="partOfGroupQuestion" :options="[
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ]" size="lg" @input="onPartOfGroupQuestionInput"></euro-radio>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isEditingOwnCompany">
      <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
        <b-modal ref="upsertModal" hide-footer @hidden="onUpsertModalHidden">
          <template #modal-header>
            <div class=" w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
              <h3>{{ upsertItemForm.id ? $t("Update Group") : $t("Create Group") }}</h3>
              <div class="d-flex align-items-center">
                <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                  @click="handleSubmit(submit)">
                  {{ upsertItemForm.id ? $t("Update") : $t("Create") }}
                </b-button>
                <b-button @click="closeUpsertModal">
                  {{ $t("Cancel") }}
                </b-button>
              </div>
            </div>
          </template>
          <validation-provider v-slot="{ errors }" name="Companies" rules="required">
            <euro-select v-model="upsertItemForm.companies" :multiselect-props="companiesMultiselectProps"
              :label="$t('Companies*')" :error-messages="errors"></euro-select>
          </validation-provider>
        </b-modal>
      </validation-observer>
    </template>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import CompanyService from "@/core/services/company/company.service";
import CompanyGroupsService from "@/core/services/company/company-groups.service";
import icons from "@/core/config/icons.js";
import { mapGetters, mapState } from "vuex";
import CountryFlag from "@/view/components/CountryFlag.vue";
import _ from "lodash";
import { backendErrorSwal } from "@/core/helpers/swal";
import Avatar from "@/view/components/avatars/Avatar.vue";

const DEFAULT_GROUP = {
  name: "",
  description: "",
  companies: [],
};

export default {
  components: {
    CountryFlag,
    Avatar
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    companyGroups: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      icons,
      apiErrors: null,
      group: this.makeDefaultGroup(),
      isSaving: false,
      isLoading: false,
      companies: [],
      partOfGroupQuestion: false,
      upsertItemForm: null,
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["getCurrentManagedCompany"]),

    companiesMultiselectProps() {
      return {
        options: this.companies
          .map(comp => {// manca la sezione in caso di persona fisica
            if (this.isEditingOwnCompany && comp.id == this.getCurrentManagedCompany.company) {
              return { ...comp, $isDisabled: true };
            }
            return comp;
          })
          .filter(comp => {
            if (!this.isEditingOwnCompany && comp.id == this.legalPerson.id)
              return false;

            return true;
          }),
        searchable: true,
        multiple: true,
        "track-by": "id",
        label: "name",
      };
    },

    currentFocusCompanyId() {
      return this.getCurrentManagedCompany.company;
    },

    currentFocusGroup() {
      return this.companyGroups.find(cg => cg.holder == this.currentFocusCompanyId) ?? DEFAULT_GROUP;
    },

    isEditingOwnCompany() {
      return this.legalPerson.id == this.currentFocusCompanyId;
    },

    isPartOfGroup() {
      return !!this.companyGroups.find(cg => cg.companies.includes(this.legalPerson.id));
    },

    canCreateGroup() {
      return this.isEditingOwnCompany && !this.isPartOfGroup;
    },

    hasCompanies() {
      return this.companies.length > 1
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          name: [...(val?.name ?? [])],
          permission_level: [...(val?.permission_level ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  async created() {
    this.isLoading = true
    try {
      const res = await CompanyService.getAllNotPaginatedFilterable({ getAdministrative: false });
      this.companies = res.results;
      await this.loadCurrentFocusCompanyGroup();
    } catch (err) {
      backendErrorSwal(err, err?.response?.data?.detail);
    } finally {
      this.isLoading = false
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Company Groups") },
    ]);
  },

  methods: {
    handleCompanyCreation(newComp) {
      this.companies.push(newComp);
      this.upsertItemForm.companies.push(newComp.id);
    },
    submit() {
      // It's an update
      if (this.upsertItemForm.id) {
        this.update(this.upsertItemForm, this.upsertItemForm.id);
        return;
      }
      this.create(this.upsertItemForm);
    },

    async update(item, id) {
      this.isSaving = true;
      try {
        await CompanyGroupsService.update(item, id);
        Swal.fire(this.$t("Success"), this.$t("Group Updated"), "success");
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    async create(item) {
      this.isSaving = true;
      try {
        await CompanyGroupsService.create(item);
        Swal.fire("Success", "Group Created", "success");
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    openEditDialog(group) {
      this.upsertItemForm = _.cloneDeep(group);
      const modal = this.$refs.upsertModal;
      modal.show();
    },

    openCreateDialog() {
      this.upsertItemForm = _.cloneDeep(this.group);
      const modal = this.$refs.upsertModal;
      modal.show();
    },

    openRemoveDialog(item) {
      Swal.fire({
        title: this.$t("DELETE"),
        text: this.$t("Are you sure you want to remove this group?"),
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async res => {
        if (res.isConfirmed) {
          try {
            await CompanyGroupsService.delete(item.id);
            this.$emit("updated");
            this.group = this.makeDefaultGroup();
          } catch (err) {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },

    onUpsertModalHidden() {
      this.apiErrors = null;
      this.upsertItemForm = null;
    },

    closeUpsertModal() {
      const modal = this.$refs.upsertModal;
      modal?.hide();
    },

    async onPartOfGroupQuestionInput(answer) {
      if (answer) {
        try {
          const body = { ...this.group, companies: [...this.group.companies, this.legalPerson.id] };
          if (!body.id) {
            await this.create(body);
            return;
          }

          await this.update(body, body.id);
        } catch (err) {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      }
      this.partOfGroupQuestion = answer;
    },

    async loadCurrentFocusCompanyGroup() {
      const currentFocusCompany = await CompanyService.getOneOldAddressFormat({ id: this.currentFocusCompanyId });
      const currentFocusCompanyGroup = currentFocusCompany.data.company_group.find(
        cg => cg.holder == this.currentFocusCompanyId,
      );

      if (currentFocusCompanyGroup) {
        // We make sure that the currentFocusCompanyId is ALWAYS inside the group's companies.
        const idx = currentFocusCompanyGroup.companies.findIndex(comp => comp == this.currentFocusCompanyId);
        const companies =
          idx < 0
            ? [this.currentFocusCompanyId, ...currentFocusCompanyGroup.companies]
            : currentFocusCompanyGroup.companies;

        this.group = { ...currentFocusCompanyGroup, companies, holder: this.currentFocusCompanyId };
        return;
      }

      this.group = this.makeDefaultGroup();
    },

    makeDefaultGroup() {
      return { ...DEFAULT_GROUP, companies: [this.currentFocusCompanyId], holder: this.currentFocusCompanyId };
    },

    getCompany(companyGroups) {
      const companyList = this.companies.filter(company => {
        if (companyGroups.includes(company.id)) {
          return company
        }
      })

      return companyList
    }
  },
};
</script>

<style lang="scss" scoped>
.gap-3 {
  gap: 3rem;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
