var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading || _vm.isSaving,
      "rounded": "sm"
    }
  }, [_vm.isEditingOwnCompany || _vm.isPartOfGroup ? [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title",
    class: {
      'align-items-start flex-column': _vm.isEditingOwnCompany
    }
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Company Group")) + " ")]), _vm.isEditingOwnCompany ? _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Update your company's groups")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "card-toolbar"
  }, [_vm.isEditingOwnCompany ? [_vm.canCreateGroup ? _c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "disabled": !_vm.hasCompanies
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openCreateDialog.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Group.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add group")) + " ")]) : _c('div', [_c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openEditDialog(_vm.companyGroups[0]);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Design/Edit.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t('Edit Group')) + " ")]), _c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder ml-4",
    on: {
      "click": function click($event) {
        return _vm.openRemoveDialog(_vm.companyGroups[0]);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Trash.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t('Delete Group')) + " ")])])] : _vm._e()], 2)]), _vm.companyGroups.length ? _c('div', {
    staticClass: "card-body detail"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-muted font-weight-bolder text-uppercase",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t('Company')))]), _c('th', {
    staticClass: "text-muted font-weight-bolder text-uppercase",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t('Own')))]), _c('th', {
    staticClass: "text-muted font-weight-bolder text-uppercase",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t('Address')))]), _c('th', {
    staticClass: "text-muted font-weight-bolder text-uppercase",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t('Nation')))])])]), _c('tbody', _vm._l(_vm.getCompany(_vm.companyGroups[0].companies), function (company) {
    var _company$name, _company$companyaddre, _company$companyaddre2;
    return _c('tr', {
      key: company.id
    }, [_c('td', [_c('div', {
      staticClass: "text-capitalize d-flex align-items-center"
    }, [_c('Avatar', {
      attrs: {
        "avatar-image": company === null || company === void 0 ? void 0 : company.logo,
        "avatar-text": company === null || company === void 0 ? void 0 : (_company$name = company.name) === null || _company$name === void 0 ? void 0 : _company$name[0]
      }
    }), _c('div', {
      staticClass: "ml-3"
    }, [_c('div', {
      staticClass: "font-weight-bolder",
      style: {
        wordBreak: 'break-word'
      }
    }, [_vm._v(_vm._s(company === null || company === void 0 ? void 0 : company.name))]), company.description ? _c('div', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(company.description))]) : _vm._e()])], 1)]), _c('td', [company.id == _vm.user.selected_company ? _c('span', {
      staticClass: "font-weight-bolder text-muted"
    }, [_vm._v(" " + _vm._s(_vm.$t("Your company")) + " ")]) : _c('span', {
      staticClass: "font-weight-bolder text-muted"
    }, [_vm._v(_vm._s(_vm.$t("Company group")))])]), _c('td', [_c('span', [_vm._v(_vm._s(company === null || company === void 0 ? void 0 : (_company$companyaddre = company.companyaddress_set) === null || _company$companyaddre === void 0 ? void 0 : (_company$companyaddre2 = _company$companyaddre[0]) === null || _company$companyaddre2 === void 0 ? void 0 : _company$companyaddre2.address.formatted))])]), _c('td', [_c('country-flag', {
      attrs: {
        "country-iso": company.country_of_establishment
      }
    })], 1)]);
  }), 0)])]) : _c('div', {
    staticClass: "card-body text-center"
  }, [_c('p', {
    staticClass: "font-weight-bold font-size-h2"
  }, [_vm._v(_vm._s(_vm.$t('Company is not part of a group')))]), _c('p', {
    staticClass: "font-size-h4 pt-3 pb-8 ml-4 text-muted"
  }, [_c('img', {
    attrs: {
      "src": "/media/svg/icons/Code/Info-circle.svg",
      "alt": "info"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Here you can create a group of companies. Being part of a group enables the possibility to use cost reversal on assignments ')) + " ")]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasCompanies,
      expression: "!hasCompanies"
    }],
    staticClass: "text-danger font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Add at least one company or a physical person with a vat document to create a group of companies')) + " ")])])])] : [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Company Groups")) + " ")])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex gap-3"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Is this company part of your group?")))]), _c('euro-radio', {
    attrs: {
      "value": _vm.partOfGroupQuestion,
      "options": [{
        text: 'Yes',
        value: true
      }, {
        text: 'No',
        value: false
      }],
      "size": "lg"
    },
    on: {
      "input": _vm.onPartOfGroupQuestionInput
    }
  })], 1)])])], _vm.isEditingOwnCompany ? [_c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          ref: "upsertModal",
          attrs: {
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onUpsertModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h3', [_vm._v(_vm._s(_vm.upsertItemForm.id ? _vm.$t("Update Group") : _vm.$t("Create Group")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.upsertItemForm.id ? _vm.$t("Update") : _vm.$t("Create")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeUpsertModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('validation-provider', {
          attrs: {
            "name": "Companies",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-select', {
                attrs: {
                  "multiselect-props": _vm.companiesMultiselectProps,
                  "label": _vm.$t('Companies*'),
                  "error-messages": errors
                },
                model: {
                  value: _vm.upsertItemForm.companies,
                  callback: function callback($$v) {
                    _vm.$set(_vm.upsertItemForm, "companies", $$v);
                  },
                  expression: "upsertItemForm.companies"
                }
              })];
            }
          }], null, true)
        })], 1)];
      }
    }], null, false, 3590508436)
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }